import React, { Component, useState } from "react";

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LineChart, Line } from 'recharts';



class Dashboard extends Component {


    render() {
        const data = [
            {
                name: 'Go',
                uv: 4000,
                pv: 2400,
                amt: 2400,
            },
            {
                name: 'C++',
                uv: 3000,
                pv: 1398,
                amt: 2210,
            },
            {
                name: 'Java',
                uv: 2000,
                pv: 9800,
                amt: 2290,
            },
            {
                name: 'Python',
                uv: 2780,
                pv: 3908,
                amt: 2000,
            },
            {
                name: 'PHP',
                uv: 1890,
                pv: 4800,
                amt: 2181,
            },
            {
                name: 'ReactJS',
                uv: 2390,
                pv: 3800,
                amt: 2500,
            },
            {
                name: 'Rust',
                uv: 3490,
                pv: 4300,
                amt: 2100,
            },
        ];
        return <>
            <h2 className="mt-4">Registration statistic:</h2>

                <BarChart
                    width={1200}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pv" fill="#8884d8" />
                    <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>

            <h2 className="mt-4">New Matches:</h2>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
        </>;
    }
}

export { Dashboard };