import React, { Component, useState } from "react";
import MUIDataTable from 'mui-datatables';
import {API} from '../backend.js';
import CustomToolbarSelect from "./customToolbar";
import {Button, Col, Form, ListGroup, Modal, Row, Tab, Tabs} from "react-bootstrap";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {forEach} from "react-bootstrap/ElementChildren";
import {useValueContextProvider, ValueContextContext} from './valueContextProvider'
import Image from 'react-bootstrap/Image'
import './resume.css'

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function ExperienceRender(props){

    let color = ["red","green","blue","blue2"];

    if(!props.experience) return <></>
    return (<div className={"SkillContainer"}>
        {props.experience.map((key,i)=>{

            let color_id = randomIntFromInterval(0, 3);
            return <div className={"Skill "+color[color_id]}>{key.name} {key.level/(20/100)} % </div>
        })}
    </div>)
}



export default class ResumeView extends Component {


    constructor(props) {
        super(props);
        this.state = {
            resumes:   props.resumes
        };


    }

    render(){

        return (
            <Tabs defaultActiveKey="resume0" id="resumes-tab-example" className="mb-3">
                {this.state.resumes.map((key, i) =>
                    <Tab eventKey={"resume"+i} title={this.state.resumes[i].position_name}>
                        <Row className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column sm="3" >position_name</Form.Label>
                                <Col sm="9">
                                    <Form.Control type="text" placeholder="Salary" readOnly={true} value={this.state.resumes[i].position_name} />
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column sm="3" >common_position</Form.Label>
                                <Col sm="9">
                                    <Form.Control type="text" placeholder="Salary" readOnly={true} value={this.state.resumes[i].common_position} />
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column sm="3" >experience</Form.Label>
                                <Col sm="9">
                                    <ExperienceRender experience={this.state.resumes[i].experience}/>
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column sm="3" >Salary</Form.Label>
                                <Col sm="9">
                                    <Form.Control type="text" placeholder="Salary" readOnly={true} value={this.state.resumes[i].salary} />
                                </Col>
                            </Form.Group>
                        </Row>
                            <Row className="mb-3">
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3" >currency_code</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="Salary" readOnly={true} value={this.state.resumes[i].currency_code} />
                                    </Col>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3" >cv_link</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="https://.." readOnly={true} value={this.state.resumes[i].cv_link} />
                                    </Col>
                                </Form.Group>
                            </Row>
                        <Row className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column sm="3" >About</Form.Label>
                                <Col sm="9">
                                    <Form.Control as="textarea" rows={3} readOnly={true} value={this.state.resumes[i].about} />
                                </Col>
                            </Form.Group>
                        </Row>

                    </Tab>)
                }
            </Tabs>
        )

    }


}