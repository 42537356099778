import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from  "@mui/icons-material/Edit";
import { withStyles } from "tss-react/mui";
import {Modal, Button, Container, Row, Form , Col, ListGroup, Tab, Sonnet} from "react-bootstrap";
import {AddCircleOutline} from "@material-ui/icons";
import './modal.scss'
import CandidateView from "./candidate_view";
import {ValueContextProvider,ValueContextContext} from './valueContextProvider'
import {API} from "../backend";
import {NotificationContainer, NotificationManager} from "react-notifications";
const candidate_demo =  {
    "activity_level": "passive",
    "last_status_at": "2019-08-24T14:15:22Z",
    "previous_work": [
        {
            "work_months": 0,
            "description": "string",
            "position": "string",
            "start_year": 0,
            "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
            "end_year": 0,
            "end_month": 0,
            "start_month": 0,
            "company_name": "string"
        }
    ],
    "email": "string",
    "accomplishments": [
        {
            "place": "string",
            "certificate_link": "string",
            "accomplishment_type": "string",
            "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
            "year": 0,
            "name": "string"
        }
    ],
    "about": "string",
    "resumes": [
        {
            "activity_level": "passive",
            "common_position": "6676db69-0312-4ba5-ba7d-5afbca768d3e",
            "cv_link": "string",
            "position_name": "string",
            "last_status": "string",
            "currency_code": "string",
            "work_months": 0,
            "location_kind": "c704652d-fb39-44fb-8bf1-3a5311f69419",
            "about": "string",
            "experience": [
                {
                    "priority": true,
                    "level": 0,
                    "name": "string"
                }
            ],
            "last_status_at": "2019-08-24T14:15:22Z",
            "salary": 500,
            "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
            "including_previous_works": [
                "497f6eca-6276-4993-bfeb-53cbbbba6f08"
            ],
            "relocation": true,
            "additions": [
                "497f6eca-6276-4993-bfeb-53cbbbba6f08"
            ],
            "foreign_visible": true,
            "job_kind": "b9b1c576-3138-43c6-aca6-1452fc08d4fd"
        }
    ],
    "place_id": "f0968ed8-288a-49fe-ae98-74c720303b1b",
    "contacts": [
        {
            "type": "string",
            "is_preferred": true,
            "contact": "string"
        }
    ],
    "last_status": "string",
    "links": [
        {
            "link_type": "string",
            "link_url": "string"
        }
    ],
    "lat": 0,
    "birth_year": 0,
    "cv_link": "string",
    "english_level": "4d4eb4f1-94c7-42bb-987b-2f6e3a30186d",
    "relocation_list": [
        {
            "lat": 0,
            "place_id": "f0968ed8-288a-49fe-ae98-74c720303b1b",
            "place_name": "string",
            "long": 0
        }
    ],
    "name": "string",
    "image": "string",
    "avatar": "string",
    "place_name": "string",
    "fill_status": {
        "resume_statuses": [
            {
                "resume_id": "d31b78c0-2458-495d-8fcf-99c1f73d8d56",
                "percentage": 0,
                "parts": [
                    {
                        "filled": true,
                        "weight_perc": 0,
                        "name": "string"
                    }
                ]
            }
        ],
        "resumes_base_part": 0,
        "percentage": 0,
        "parts": [
            {
                "filled": true,
                "weight_perc": 0,
                "name": "string"
            }
        ]
    },
    "education": [
        {
            "months": 0,
            "level_id": "3cb85fcc-c00c-4c83-ae89-cd2568531125",
            "start_year": 0,
            "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
            "end_year": 0,
            "end_month": 0,
            "start_month": 0,
            "faculty": "string",
            "institute": "string",
            "specialization": "string"
        }
    ],
    "work_months": 0,
    "long": 0,
    "prefer_foreign": true
}

const defaultToolbarSelectStyles = {
    iconButton: {
    },
    iconContainer: {
        marginRight: "24px",
    },
    inverseIcon: {
        transform: "rotate(90deg)",
    },
};



class CustomToolbarSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {Show:false }
    }

    showHandler = null;

    setHandler =(handler)=>{this.showHandler = handler}
    handleShow = () => { return this.showHandler?this.showHandler(true):()=>{} }

    handleDeleteSelection = async () => {

        let candidate = this.props.displayData[this.props.selectedRows.data[0].dataIndex];
        console.log(candidate)
        if(candidate.data!==undefined) {
            let is_ok =  window.confirm("Are you sure you want to delete:  " + candidate.data[3] + "?");
            if (is_ok) {

                let res = await API.Candidate.Delete(candidate.data[0]);
                //let res = { result: true};
                if(res.result) {
                    ///lert(1)
                    await this.props.handleUpdate();
                    //console.log("delete ok!")
                    await NotificationManager.success('Deleted!');
                }
            }
        }

    };

    handleClickBlockSelected = () => {
        console.log(`block users with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
    };

     updateDataCallBack = async (data)=>{
       await this.props.handleUpdate();
    }

    render() {

   //     this.setState({CandidateID:, name:})
        const { classes } = this.props;

        return (
            <div className={classes.iconContainer}>

                <ValueContextProvider data={this.props.displayData[this.props.selectedRows.data[0].dataIndex]} updateDataCallBack={this.updateDataCallBack}>
                   <CandidateView setHandler={this.setHandler} candidate_id={this.props.displayData[this.props.selectedRows.data[0].dataIndex].data[0]}/>
                </ValueContextProvider>

                <Tooltip title={"Edit Resume"}>
                    <IconButton className={classes.iconButton} onClick={this.handleShow}>
                        <EditIcon className={classes.icon} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Delete Candidate"}>
                    <IconButton className={classes.iconButton} onClick={this.handleDeleteSelection}>
                        <DeleteIcon className={[classes.icon].join(" ")} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Block Candidate"}>
                    <IconButton className={classes.iconButton} onClick={this.handleClickBlockSelected}>
                        <BlockIcon className={classes.icon} />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
}

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, { name: "CustomToolbarSelect" });