import React, { Component, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./login.css";
import {auth,me,candidates} from '../backend.js';
import {Row} from "react-bootstrap";
function authw() {
    let login = "";
    let pass = "";
    let role = "headz";
    auth(login,pass, role);
}

export function  AuthForm (){
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {

        let login = email;
        let pass = password;
        let role = "headz";

        auth(login,pass, role, ()=>{
            window.location.reload()
        } , ()=>{

        });
        event.preventDefault();
    }

    return (
        <div className="Login">

            <Form onSubmit={handleSubmit}>
                <h2>Admin panel</h2>
                <Form.Group as={Row} size="lg" controlId="email" className="mb-3">
                    <Form.Label>Login</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Row} size="lg" controlId="password" className="mb-3">
                    <Form.Label>Password </Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <Row className="mb-3">
                <Button block size="lg" type="submit" disabled={!validateForm()}>
                    Login
                </Button>
                </Row>
            </Form>
        </div>
    );
}
