import logo from './logo.svg';
import React, { Component } from "react";
import ReactDOM from "react-dom";
//import './App.css';

import './styles.scss'
import {auth,me,API} from './backend.js';
import { AuthForm  } from './components/authform'
import  {Candidates} from './components/Candidates'
import  {Moderation} from './components/Moderation'
import  {Dashboard} from './components/dashboard'
import Navbar from 'react-bootstrap/Navbar'
import  {Container, NavDropdown, Nav, Breadcrumb} from  'react-bootstrap'
import { Routes, Route, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import BoardAdmin from './components/boardadmin'
import {
    ArrowRight, Building, Calendar2Day,
    EmojiHeartEyes, FilePerson,
    Newspaper, Receipt,
    Server,
    ShieldFillExclamation,
    Speedometer2
} from 'react-bootstrap-icons';
import {NotificationContainer} from "react-notifications";



function logout(){
    window.localStorage.removeItem("access_token")
    window.location.reload()
}

function GetLocation(){
    let location = useLocation();

   console.log(location)

    return<>{location.pathname.replace("/","")}</>
}


class NotFound extends Component {
    render() {
        return <>
            NOT FOUND
        </>;
    }
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[], user:{name:"UserName"} };


    }

    async componentDidMount() {

        document.addEventListener('keydown', function(event) {
            if (event.ctrlKey && event.key === 'z') {
                alert('Undo!');
            }
        });

        let isAuth = window.localStorage.getItem("access_token")
        if(isAuth) {

            me((data)=>{
                this.setState({user:{
                        name:data.name}
                })
            });

            let common_positions =  await API.Refs.Get("common_positions");
            window.localStorage.setItem("ref_common_positions",JSON.stringify(common_positions.references.common_positions))

            let positions =  await API.Refs.Get("positions");
            window.localStorage.setItem("ref_positions",JSON.stringify(positions.references.positions))

            let english_levels =  await API.Refs.Get("english_levels");
            window.localStorage.setItem("ref_english_levels",JSON.stringify(english_levels.references.english_levels))

            let cities =  await API.Refs.Get("cities");
            window.localStorage.setItem("ref_cities",JSON.stringify(cities.references.cities))
        }


    }


    render() {
        let isAuth = window.localStorage.getItem("access_token")
        if(isAuth) {

            return (

                <div className="d-flex" id="wrapper">

                    <div className="border-end bg-white" id="sidebar-wrapper">
                        <div className="sidebar-heading border-bottom bg-light" style={{height:"63px"}}>
                            Headzly Admin
                            </div>
                        <div className="list-group list-group-flush">

                           <Link className="list-group-item list-group-item-action list-group-item-light p-3"  to='/dashboard'> <Speedometer2  size={30} />  Dashboard</Link>
                            <Link className="list-group-item list-group-item-action list-group-item-light p-3"  to="/moderation"><ShieldFillExclamation  size={30} /> Moderation</Link>
                            <Link className="list-group-item list-group-item-action list-group-item-light p-3  "  to="/board"><Calendar2Day  size={30} /> Board Requests</Link>
                            <Link className="list-group-item list-group-item-action list-group-item-light p-3"  to="/candidates"><FilePerson  size={30} /> Candidates</Link>
                            <Link className="list-group-item list-group-item-action list-group-item-light p-3 disabled"  to="/Vacancies"><Receipt  size={30} /> Vacancies</Link>
                            <Link className="list-group-item list-group-item-action list-group-item-light p-3 disabled"  to="/Companies"><Building  size={30} /> Companies</Link>

                            <Link className="list-group-item list-group-item-action list-group-item-light p-3 disabled"  to="/Matching"><EmojiHeartEyes  size={30} /> Matching</Link>
                            <Link className="list-group-item list-group-item-action list-group-item-light p-3 disabled"  to="/Logs"><Server  size={30} /> Logs</Link>
                        </div>
                    </div>

                    <div id="page-content-wrapper">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                                        <Navbar.Collapse id="basic-navbar-nav">
                                            <Nav className="ms-auto">

                                                <NavDropdown title={
                                                    <>   <img width={30}  src="https://re.headz.io/files/av6.png"  alt="user pic"/>
                                                        <span className="text-info font-weight-bold">  {this.state.user.name}  </span>
                                                   </>
                                                } id="basic-nav-dropdown ">

                                                    <NavDropdown.Item href="#action/3.2">Settings</NavDropdown.Item>
                                                    <NavDropdown.Item href="#action/3.3">Users</NavDropdown.Item>
                                                    <NavDropdown.Divider />
                                                    <NavDropdown.Item  onClick={logout}>Exit</NavDropdown.Item>
                                                </NavDropdown>
                                            </Nav>
                                        </Navbar.Collapse>

                        </nav>
                        <div className="container-fluid">
                        <Breadcrumb>
                            <Breadcrumb.Item href="#">Admin</Breadcrumb.Item>
                            <Breadcrumb.Item active> <GetLocation/></Breadcrumb.Item>
                        </Breadcrumb>
                        </div>
                        <div className="container-fluid">

                            <Routes>
                                <Route path="/"  element={<Dashboard/>}></Route>
                                <Route path="/board" element={<BoardAdmin/>}></Route>
                                <Route path="/dashboard"  element={<Dashboard/>}></Route>
                                <Route path="/moderation" element={<Moderation/>}></Route>
                                <Route path="/candidates" element={<Candidates/>}></Route>
                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>


            )
        }
            else
            return (
                <AuthForm/>
            )
        }

    render2() {
        let isAuth = window.localStorage.getItem("access_token")
        if(isAuth){

        return (

            <section id="page">
                <header>
                    <Navbar bg="light" expand="md">
                        <Container>
                            <Navbar.Brand href="/home">Admin</Navbar.Brand>

                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">


                                    <NavDropdown title="Profile" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="#action/3.2">Settings</NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.3">Users</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item  onClick={logout}>Exit</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                </header>


                <Nav defaultActiveKey="/home" className="flex-column">
                    <Nav.Link href="/moderation">Модерация</Nav.Link>
                    <Nav.Link href="/candidates">Кандидаты</Nav.Link>
                    <Nav.Link eventKey="link-1" disabled>Вакансии</Nav.Link>
                    <Nav.Link eventKey="link-1" disabled>Клиенты</Nav.Link>

                </Nav>


                <main>

                </main>
                <footer>statusbar</footer>

            </section>
        );}
        else{
            return (
            <AuthForm/>
            )
        }
    }
}

export default App;
