
import React, {Component, useRef, useState} from "react";
import ReactDOM from 'react-dom'
import MUIDataTable from 'mui-datatables';
import {API} from '../backend.js';
import CustomToolbarSelect from "./customToolbar";
import CandidateView  from './candidate_view'

import  {Badge, OverlayTrigger,Overlay , Tooltip, Popover, Button} from  'react-bootstrap'
import {makeStyles} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import moment from "moment";


const SPACED_DATE_FORMAT = "DD.MM.YYYY HH:mm";


function openWnd(id){

    window.open('https://re.headz.io/candidate_resume/'+id, '_blank').focus();
}

function DrawFlow(value){

    document.dispatchEvent(new Event('neve-update', { bubbles: true }));

    const statusName = value.value;
    return <div style={{ display: "flex",justifyContent: "center", alignItems: "center", flexDirection:"column"}}>
             <div >
                 { statusName === "new"?<Badge pill bg="success">new</Badge> :<Badge pill bg="secondary">new</Badge>}
             </div>
             <div>
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      className="bi bi-arrow-down" viewBox="0 0 16 16">
                     <path fill-rule="evenodd"
                           d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                 </svg></div>
             <div>
                 { statusName === "registered"?<Badge pill bg="success">registered</Badge> :<Badge pill bg="secondary">registered</Badge>}
             </div>
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-arrow-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                  </svg></div>
              <div>
                  { statusName === "boarded"?<Badge pill bg="success">boarded</Badge> :<Badge pill bg="secondary">boarded</Badge>}
              </div>
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-arrow-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                  </svg></div>
              <div>
                  { statusName === "moderation"?<Badge pill bg="success">moderation</Badge> :<Badge pill bg="secondary">moderation</Badge>}
              </div>
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-arrow-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                  </svg></div>
             <div>
                 { statusName === "active"?<Badge pill bg="success">active</Badge> :<Badge pill bg="secondary">active</Badge>}
             </div>
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-arrow-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                  </svg></div>
             <div>
                 { statusName === "inactive"?<Badge pill bg="success">inactive</Badge> :<Badge pill bg="secondary">inactive</Badge>}
             </div>
          </div>

}


class Candidates extends Component {

    constructor(props) {
        super(props);
        this.state = { data:[] , stp:"replace", currentPage:0, rowsPerPage:10, data_count:0, searchText:"", sortOrder:"-"};

    }


    async componentDidMount() {

       let candidates_list = await API.Candidate.List(this.state.currentPage, this.state.rowsPerPage, this.state.sortOrder)
       this.setState({data: candidates_list})
        this.setState({data_count: candidates_list.total})
    }

     handleUpdateData = async (event)=> {
         let candidates_list = await API.Candidate.List(this.state.currentPage, this.state.rowsPerPage, this.state.sortOrder)
         this.setState({data: candidates_list})
         this.setState({data_count: candidates_list.total})
        // this.setState({data_count: candidates_list.total})
    }

    pageChangeHandler =  async   (p)=> {
       //  alert(p)
         await this.setState({currentPage: p})
         await this.handleUpdateData()

    }
    onChangeRowsPerPage =  (p)=>{
         this.setState({rowsPerPage: p})
    }
    render() {


        const columns = [
            {label: 'id', name: 'id', options: {  display: false}},
            { label: 'RegDate', name: 'created_at',      options: {
                    filter: true,
                    sort: true,

                    customBodyRender: value =>
                        moment(new Date(value)).format(SPACED_DATE_FORMAT)
                } },
            { label: 'Name', name: 'name' },
            { label: 'Email', name: 'email' },
            { label: 'Status', name: 'last_status', options: {
                    customBodyRender:  (value)=>{

                        const popover = (<div >
                            <Popover id="popover-basic" >
                                <Popover.Header as="h3">Headz Flow</Popover.Header>
                                <Popover.Body >
                                    <DrawFlow value={value} />
                                </Popover.Body>
                            </Popover>
                            </div>
                        );

                        const Example = () => (

                            <OverlayTrigger   trigger={["hover", "hover"]} placement="right" overlay={popover}>
                                <Badge pill bg="dark">{value}</Badge>
                            </OverlayTrigger>
                        );

                        return  <Example />;
                    }
                } },
            { label: 'Comments', name: 'comments', options: {
                    customBodyRender:  (value)=>{
                        if(value) {
                            return <button>Open</button>
                        }else{
                            return "None"
                        }
                    }
                } },
            { label: 'Resumes', name: 'resumes', options: {
                    customBodyRender:  (value, sss, sss2)=>{
                        let res_data = sss.tableData[sss.rowIndex][6]

                        if(res_data && res_data.length>0) {
                            return <Badge pill  bg="success"  onClick={()=>{ openWnd(res_data[0].id) }}>{res_data[0].position_name}</Badge>
                        }else{
                            return "None"
                        }
                    }
            } }
        ];

        const options = {
            onColumnSortChange: async (changedColumn, direction) => {

                if (direction === 'asc') {
                    //alert("sort change")
                    await this.setState({sortOrder:''})
                    await this.handleUpdateData()
                } else{
                    //alert("sort change-")
                    await this.setState({sortOrder:'-'})
                    await this.handleUpdateData()
                }
                //await this.handleUpdateData();
                //this.sort(changedColumn, order);
            },
            searchText: this.state.searchText,
            searchProps: {

                onKeyUp:async (e) => {
                    if(e.keyCode === 13) {

                        let candidates_list = await API.Candidate.EmailSearch(e.target.value)
                        await this.setState({data: candidates_list})

                    }

                }
            },
            searchPlaceholder: 'Query for search by email',

            serverSide: true,
            onTableChange: async (action, tableState) => {

                if(action!=='propsUpdate' && action!=='rowSelectionChange' && action!=='search' && action!=='pageChange' ) {

                   // alert(action)
                   // console.log(action)
                    await this.handleUpdateData()
                }
                //await this.setState({data_count: candidates_list.total})
            },
            count: Math.round(this.state.data_count),
            filterType: 'dropdown',
            responsive: 'stacked',
            selectableRows:'single',
            onChangePage: this.pageChangeHandler,
            onChangeRowsPerPage: this.onChangeRowsPerPage,
            onRowsDelete:(ds)=>{test(ds)},
            selectToolbarPlacement: this.state.stp,
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} handleUpdate={this.handleUpdateData}/>
            ),
        };


        return    <div style={{ maxWidth: '100%' }}>
            <MUIDataTable
                columns={columns}
                data={ this.state.data.listing}
                title='Candidates'
                options={options}

            />
        </div>
    }
}

export {Candidates};