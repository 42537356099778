import React, { Component, useState } from "react";
import MUIDataTable from 'mui-datatables';
import {API} from '../backend.js';
import CustomToolbarSelect from "./customToolbar";
import {Button, Col, Form, ListGroup, Modal, Row, Tab, Tabs} from "react-bootstrap";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {forEach} from "react-bootstrap/ElementChildren";
import {useValueContextProvider, ValueContextContext} from './valueContextProvider'
import Image from 'react-bootstrap/Image'
import ResumeView from './resume_view'
const contactsSchema = {
    "is_preferred": true,
    "contact":  {"type":"string"},
    "type": {"type":"enum", "enums":["email","phone"]}
}


const candidateSchemaBinding = {
    "id": {"type":"string", "readonly":true, "control":"text"},
    "name": {"type":"string"  ,"control":"text"},
    "image": {"type":"string" ,"control":"text"},
    "avatar": {"type":"string" ,"control":"text"},
    "email": {"type":"string", "readonly":true ,"control":"text"},
    "confirmation_token":{"type":"string", "readonly":true ,"control":"text"},
    "last_status": {"type":"enum", "enums":["new","registered","boarded","moderation", "active", "inactive"], "display_name":"Status"},
    "activity_level": {"type":"enum", "enums":["passive","suspend","active"]},
    "previous_work": {"type":"arrayOfObjects"},
    "accomplishments": {"type":"arrayOfObjects"},
    "about": {"type":"string" ,"control":"memo"},
    "place_id": {"type":"ref", "ref_id":"ref_cities"},
    "contacts": {"type":"arrayOfObjects", "contract":contactsSchema},
    "links": {"type":"arrayOfObjects"},
    "lat": {"type":"num"},
    "birth_year": {"type":"num"},
    "cv_link": {"type":"string" ,"control":"text"},
    "english_level": {"type":"ref", "ref_id":"ref_english_levels"},
    "relocation_list":"array",
    "place_name": {"type":"string" ,"control":"text"},
    "education":  {"type":"arrayOfObjects"},
    "long": {"type":"num"},
    "prefer_foreign": {"type":"bool", "description":"Candidate prefer vacancies in foreign companies"}
}

function renderControl( meta, id,  placeholder, value_in, onChange){

    if(meta&&meta.display_name){
        placeholder = meta.display_name;
    }

    if(meta&&meta.type === "num") {
            return <Form.Control readonly={meta.readonly ? meta.readonly : false} type="text" placeholder={placeholder}
                                 id={id} value={value_in} onChange={onChange}/>
    }

    if(meta&&meta.type === "string"){

        if(meta.control==="text")
        return <Form.Control readonly={meta.readonly?meta.readonly:false} type="text" placeholder={placeholder} id={id} value={value_in} onChange={onChange}/>
        if(meta.control==="memo")
        return <Form.Control readonly={meta.readonly?meta.readonly:false}  as="textarea" placeholder={placeholder} id={id} value={value_in} onChange={onChange}/>
    }

    if(meta&&meta.type === "arrayOfObjects"){

        if(meta.contract && value_in) {
            let ref_data = meta.contract;
            if(placeholder==="contacts")

              return (value_in.map((data, i) => {
                   return <Form.Control readonly={true} type="text" placeholder={data.contact} id={id} value={data.contact}  onChange={onChange} />
                })

            )
        }
    }


    if(meta&&meta.type === "enum"){

        let ref_data= meta.enums;

        return (<Form.Select readonly={meta.readonly ? meta.readonly : false} id={id} onChange={onChange}
                             defaultValue={value_in}>
                {ref_data.map((data, i) => {
                    return <option value={data}>{data}</option>
                })}
            </Form.Select>
        )
    }
    if(meta&&meta.type === "bool"){

        return( <Form.Check
            id={id} onChange={onChange}
            type="switch"
            label={meta.description}
            value={value_in}
        />);
    }
    if(meta&&meta.type === "ref"){

            let ref_data= JSON.parse(window.localStorage.getItem(meta.ref_id))

            return (<Form.Select readonly={meta.readonly ? meta.readonly : false} id={id} onChange={onChange}
                                 defaultValue={value_in}>
                    {ref_data.map((data, i) => {
                        return <option value={data.id}>{data.name}</option>
                    })}
                </Form.Select>
            )
    }
}



function FormGenerator(props){

    let columns = props.columns?props.columns:2;
    if(props.candidateData){
    const proxyData = props.candidateData;
    let previosKey = ""
    return (
        <>
            {Object.entries(proxyData).map((key, i) =>{

                let realKey = key[0];
                if(  realKey ==="fill_status" ||realKey ==="email"
                    || realKey ==="id" || realKey ==="name"
                    || realKey==="resumes"|| realKey==="avatar"|| realKey==="last_status_data"
                    || realKey ==="long" || realKey ==="last_status" ) {
                    return
                }

                let meta = candidateSchemaBinding[realKey];
                if(i % columns === 0 && i!==0) {
                            if(columns===2){ return (
                                <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>{previosKey}</Form.Label>
                                    <Form.Control type="text" placeholder={previosKey} id={previosKey}
                                                  value={props.getValue(previosKey, proxyData)} onChange={props.handleChange}/>
                                </Form.Group>
                                 <Form.Group as={Col}>
                                    <Form.Label >{key[0]}</Form.Label>
                                    <Form.Control type="text" placeholder={key[0]} id={key[0]}
                                                  value={props.getValue(key[0], proxyData)} onChange={props.handleChange}/>
                                </Form.Group>
                           </Row>
                        ) }else {
                                return (
                                    <Row className="mb-3">
                                    <Form.Group as={Row}>
                                    <Form.Label column sm="2">{key[0]}</Form.Label>
                                        <Col sm="10">
                                            {renderControl(meta, realKey,realKey,props.getValue(realKey, proxyData), props.handleChange)}
                                        </Col>


                                    </Form.Group></Row>)
                            }
                } else {
                    previosKey = key[0];
                    return;
                }
            })
            }
           </>
    )
    }else return <>
    </>
}

export default class CandidateView extends Component {
    static contextType = ValueContextContext

    constructor(props) {
        super(props);
        this.state = {ConfirmDlgShow:false, Show:false, data: {}, stp: "replace" ,  candidate_id: props.candidate_id, candidateData: null , undoData:{}, submit_data: {}};

         props.setHandler((state)=>{
             this.setState({Show:state})
         })

    }

   async  componentDidMount() {

       let candidateData = await API.Candidate.Get(this.state.candidate_id, this.state.submit_data)
       this.setState({candidateData:candidateData})
       this.setState({undoData:JSON.parse(JSON.stringify(candidateData))})
    }

     getChangesText= ()=>{
        let res = ""
         for (const [key, value] of Object.entries(this.state.submit_data)) {
            res=res+"\n"+key+": ["+this.state.undoData[key]+"] -> ["+this.state.submit_data[key]+"]";
        }
         return res;
     }

     saveHandler = async ()=>{
         console.log(this.context)
        //alert(this.state.candidate_id)
         let res_confirm = window.confirm("Save changes?"+this.getChangesText())
         if(res_confirm)
        if(this.state.candidate_id!==null) {
           let res = await API.Candidate.Set(this.state.candidate_id, this.state.submit_data, async ()=>{
               NotificationManager.success('Saved!');
               await this.context.updateDataCallBack()
               this.setState({Show:false});

           })

        }
    }

    setValue(valueName, value){

        let localCandidateData = this.state.candidateData;
        localCandidateData[valueName] = value;
        this.setState({candidateData: localCandidateData});

        let localSubmit_data = this.state.submit_data;
        localSubmit_data[valueName] = value;
        this.setState({submit_data:localSubmit_data});
    }

    //getter
    getValue(valueName, externalData){
        if(externalData) {
            return externalData[valueName];
        }else{
            return this.state.candidateData?this.state.candidateData[valueName]:"empty";
        }
    }

    //Form handlers
    handleChange = e => {
        console.log(e.target)
        this.setValue(e.target.id, e.target.value )
    };

    onStatusChange(val1, val2 , val3) {
        console.log(val1,val2,val3)
        let ok = window.confirm("Status will be changed!\nAre you sure?")
        if(ok){
            alert(ok)
        } else{
            alert(ok)
        }

    }

    render() {

        //alert(JSON.stringify(this.context))
          return (<>
              <NotificationContainer/>

              <Modal size="lg" show={this.state.Show} onHide={()=>this.setState({Show:false})} className="modal-container custom-map-modal">
                  <Modal.Header closeButton>
                      <Modal.Title >{ this.getValue("name") }({ this.getValue("email") })</Modal.Title>

                  </Modal.Header>
                  <Modal.Body>
                  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                  <Tab eventKey="home" title="Main Info">
                  <Row>
                      <Col>
                          <Form>
                              <Row  className="mb-3">
                                  <Form.Group as={Row}>
                                      <Form.Label column sm="2">Avatar</Form.Label>
                                      <Col sm="2">
                                          <Image className="border border-success border-3"   src={"https://re.headz.io/"+this.getValue("avatar")} />
                                      </Col>
                                      <Col sm="1">
                                          <Image   src={"https://re.headz.io/files/av1.png"} />
                                      </Col>
                                      <Col sm="1">
                                          <Image   src={"https://re.headz.io/files/av2.png"} />
                                      </Col>
                                      <Col sm="1">
                                          <Image   src={"https://re.headz.io/files/av3.png"} />
                                      </Col>
                                          <Col sm="1">
                                          <Image   src={"https://re.headz.io/files/av4.png"} />
                                          </Col>
                                      <Col sm="1">
                                      <Image   src={"https://re.headz.io/files/av5.png"} />
                                        </Col>
                                      <Col sm="1">
                                      <Image   src={"https://re.headz.io/files/av6.png"} />
                                      </Col>
                                  </Form.Group>
                              </Row>
                              <Row  className="mb-3">

                                  <Form.Group as={Row}>
                                      <Form.Label column sm="2">Name</Form.Label>
                                      <Col sm="10">
                                        <Form.Control type="text" placeholder="name" id={"name"} value={this.getValue("name")} onChange={this.handleChange}  />
                                      </Col>
                                  </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                  <Form.Group as={Row}>
                                      <Form.Label column sm="2" >User Id</Form.Label>
                                      <Col sm="10">
                                      <Form.Control type="text" placeholder="Id" readOnly={true} value={this.state.candidate_id} />
                                      </Col>
                                  </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                  <Form.Group as={Row}>
                                      <Form.Label column sm="2">Email</Form.Label>
                                      <Col sm="10">
                                            <Form.Control type="email" readOnly={true} placeholder="Enter email" value={this.getValue("email")}  />
                                      </Col>
                                  </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                  <Form.Group as={Row}>
                                      <Form.Label column sm="2">Status</Form.Label>
                                      <Col sm="10">
                                          <Form.Select readOnly={true} id={"last_status"} onChange={this.onStatusChange} defaultValue={this.getValue("last_status")} >

                                              {["new","registered","boarded","moderation", "active", "inactive"].map((data, i) => {
                                                  return <option value={data}>{data}</option>
                                              })}

                                          </Form.Select>
                                      </Col>
                                  </Form.Group>
                              </Row>
                              <FormGenerator columns={1} handleChange={this.handleChange} getValue={this.getValue} candidateData={this.state.candidateData} />
                          </Form>
                      </Col>
                  </Row>

                  </Tab>
                      <Tab eventKey="resumes" title="Resumes">
                         <ResumeView resumes={this.state.candidateData?this.state.candidateData.resumes:[]}/>
                      </Tab>
                  </Tabs>
                  </Modal.Body>
                  <Modal.Footer>
                      <Button variant="secondary" onClick={()=>this.setState({Show:false})}>
                          Close
                      </Button>
                      <Button variant="primary" onClick={this.saveHandler}>
                          Save Changes
                      </Button>
                  </Modal.Footer>
              </Modal>

                 </>)

    }

}