import React, { useState } from "react";

export let ValueContextContext = React.createContext(null)

export function ValueContextProvider({children, data , updateDataCallBack}) {

    return (
        <ValueContextContext.Provider value={{data, updateDataCallBack}} >
            {children}
        </ValueContextContext.Provider>
    )
    }

export function useValueContextProvider() {
    return React.useContext(ValueContextContext)
}
