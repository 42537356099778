
const API_BASE = "https://re-api.headz.io";

const enumValue = (name) => Object.freeze({toString: () => name});

export const BP = Object.freeze({

    New : enumValue("new"),
    Registered : enumValue("registered"),
    Boarded : enumValue("boarded"),
    Moderation : enumValue("moderation"),
    Active: enumValue("active"),
    Inactive: enumValue("inactive"),

});

export class API {

    static  Moderation = class {

        static async GetModerationList(callback) {
            let access_token = window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE + '/admin/candidates?statuses=moderation&page_size=200&sort=-updated_at', {
                    method: 'GET', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + access_token,
                    }
                }
            );
            return await response.json();
        }
        static async Accept(candidate_id, reason = "") {
            let access_token = window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE + '/admin/candidates/'+candidate_id+'/accept', {
                    method: 'POST', // or 'PUT'
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + access_token,
                    },
                body:JSON.stringify({
                    reason:"",
                    user_message: ""
                })
                }
            );
            return await response.json();
        }

        static async Reject(candidate_id, reason = "", message = "") {
            let access_token = window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE + '/admin/candidates/'+candidate_id+'/reject', {
                    method: 'POST', // or 'PUT'
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + access_token,
                    },
                  body:JSON.stringify({
                      reason:reason,
                      user_message: message
                  })
                }
            );
            return await response.json();
        }
        static async SetBP(candidate_id, BP_status ) {
            let access_token = window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE + '/candidates/'+candidate_id+'/bp/'+BP_status, {
                    method: 'POST', // or 'PUT'
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + access_token,
                    }
                }
            );
            return await response.json();
        }
    }

    static Candidate = class {

        static async Get(candidate_id) {
            let access_token = window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE + '/candidates/'+candidate_id, {
                    method: 'GET', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + access_token,
                    }
                }
            );
            return await response.json();
        }

        static async Set(candidate_id, data, on_done) {
            let access_token = window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE + '/candidates/'+candidate_id, {
                    method: 'PATCH', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + access_token,
                    },
                body:JSON.stringify(data)
                }
            );
            if(response.ok){
                if(on_done)
                on_done()
            }
            return await response.json();
        }
        static async Delete(candidate_id){
            let access_token =  window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE+'/admin/candidates/'+candidate_id,{
                    method: 'DELETE', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+access_token,
                    }
                }
            );
            return await  response.json();
        }
        static async EmailSearch(query){
            let access_token =  window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE+'/admin/candidates?sort=-created_at&page_size=100&q_email='+query,{
                    method: 'GET', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+access_token,
                    }
                }
            );
            return await  response.json();
        }

        static async List(offset=0, rowsPerPage=10, sortOrder= "-"){
            let access_token =  window.localStorage.getItem("access_token");
           let response = await fetch(API_BASE+'/admin/candidates?sort='+sortOrder+'created_at&page_size='+rowsPerPage+'&offset='+offset*rowsPerPage,{
                    method: 'GET', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+access_token,
                    }
                }
            );
            return await  response.json();
        }

    }

    static Refs = class {
        static async Get(refs, loc) {
            if(loc===undefined){
                loc = "en_us"
            }
            let access_token = window.localStorage.getItem("access_token");
            let response = await fetch(API_BASE + '/references/?refs='+refs+"&loc="+loc, {
                    method: 'GET', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + access_token,
                    }
                }
            );
            return await response.json();
        }

    }
}



//https://api.headz.io/admin/board/requests
export function bRequests(callback){
    let access_token =  window.localStorage.getItem("access_token");
    fetch(API_BASE+'/admin/board/requests',{
            method: 'GET', // or 'PUT'
            headers: {
                'Authorization': 'Bearer '+access_token,
            }
        }
    ).then(response=>response.json())
        .then(json => callback(json));

}





export function auth(login, pass, role, hook, error_cb){


        fetch(API_BASE+'/auth',{
                method: 'POST', // or 'PUT'
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify({
                    "long": "string",
                    "email": login,
                    "role": role,
                    "passw": pass
                }),
            }
        )
            .then(response => response.json())
            .then(data => {
                if(data.token!==undefined) {
                    window.localStorage.setItem('access_token', data.token)
                    hook()
                }else{
                    alert(data.data.title)
                }
            }).catch(error=>error_cb(error));
    }

///TODO: refactoring
/// OLD API STYLE
export function me(cb){
//https://api.headz.io/me
    let access_token =  window.localStorage.getItem("access_token");
    fetch(API_BASE+'/me',{
            method: 'GET', // or 'PUT'
            headers: {
                'Authorization': 'Bearer '+access_token,
            }
        }
    )
        .then(response => response.json())
        .then(data => {
            cb(data)
            console.log(data)

        });
}

export async function candidates(callback){

    let access_token =  window.localStorage.getItem("access_token");
    fetch(API_BASE+'/admin/candidates',{
            method: 'GET', // or 'PUT'
            headers: {
                'Authorization': 'Bearer '+access_token,
            }
        }
    ).then(response=>response.json())
     .then(json => callback(json));
}


