import React, { Component, useState } from "react";
import {Badge, Button, Modal} from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import {API, BP} from "../backend";
import { Col, Form, ListGroup, Row, Tab, Tabs} from "react-bootstrap";
import moment from "moment";
const SPACED_DATE_FORMAT = "DD.MM.YYYY HH:mm";


function openWnd(id){

    window.open('https://re.headz.io/candidate_resume/'+id, '_blank').focus();
}

class Moderation extends Component {


    constructor(props) {
        super(props);
        this.state = { hasError: false, items:[], RejectShow:false, RejectMessageShow:true, user_message: "", RejectReason:"" };

    }
    async componentDidMount() {
        let res = await API.Moderation.GetModerationList();
        this.setState({ items:res.listing  });

    }
    async saveHandler(event){

        await API.Moderation.Reject( this.state.CurrentCandidateID, this.state.RejectReason, this.state.user_message)
        await this.setState({RejectShow:false})
        let res = await API.Moderation.GetModerationList();
        this.setState({ items:res.listing  });

    }
    render() {
       // let candidate_id = sss.tableData[sss.rowIndex][0]
        const columns = [
            {label: 'id', name: 'id', options: {  display: false}},
            { label: 'RegDate', name: 'created_at',      options: {
                    filter: true,
                    sort: true,
                    customBodyRender: value =>
                        moment(new Date(value)).format(SPACED_DATE_FORMAT)
                } },
            { label: 'Email', name: 'email'},
            { label: 'Name', name: 'name' },

            { label: 'Resume', name: 'resumes', options: {
                    customBodyRender:  (value, sss)=>{
                        let res_data = sss.tableData[sss.rowIndex][4]
                        //console.log(res_data)

                        if(res_data && res_data.length>0) {
                            return <Badge pill  bg="success"  onClick={()=>{ openWnd(res_data[0].id) }}>{res_data[0].position_name}</Badge>
                        }else{
                            return "None"
                        }
                    }

                } },
            { label: 'Actions', name: ' ', options:   {
                    customBodyRender:  (value, sss)=>{

                        let candidate_id = sss.tableData[sss.rowIndex][0]
                        //this.setState({CurrentCandidateID: candidate_id})
                        ///console.log({candidate_id:candidate_id, value:sss.tableData[sss.rowIndex]})
                        return <>
                            <Button variant="success" size={"sm"} onClick={async ()=>{
                                await API.Moderation.Accept( candidate_id, BP.Active)
                                let res = await API.Moderation.GetModerationList();
                                await this.setState({items: res.listing});
                            } }>Accept</Button>
                            <Button variant="danger" size={"sm"} onClick={async ()=>{

                               /* let msg = prompt("Please type reject message for user:")
                                if(msg!==null) {
                                    await API.Moderation.Reject(candidate_id, "", msg)
                                    let res = await API.Moderation.GetModerationList();
                                    await this.setState({items: res.listing});
                                }*/
                                await this.setState({CurrentCandidateID: candidate_id})
                                await this.setState({RejectShow:true})
                            }}>Reject</Button>
                        </>
                    }}},
        ]
        const options = {
            filterType: 'dropdown',
            responsive: 'stacked',
            selectableRows:'single'
        }
        return (<div style={{ maxWidth: '100%' }}>

            <Modal size="md" show={this.state.RejectShow} onHide={()=>this.setState({RejectShow:false})} className="modal-container">
                <Form  >
                <Modal.Header closeButton>
                    <Modal.Title> Reject Candidate </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group     onChange={(val)=>{
                        console.log(val.target)

                        if(val.target.value === "4"){
                            this.setState({RejectMessageShow: false})

                        }else {
                            this.setState({RejectMessageShow: true})

                        }

                        this.setState({RejectReason: val.target.value })

                    }}>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check

                        label="Work experience is less then 1 year."
                        name="group1"
                        type="radio"

                        value={"junior"}
                    />
                    <Form.Check
                        defaultChecked={true}
                        label="Wrong data in resume"
                        name="group1"
                        type="radio"
                        value={"content"}
                    />
                    <Form.Check

                        label="Silent reject (without email)"
                        name="group1"
                        type="radio"
                        value={"custom"}
                    />
                    <Form.Check
                        label="Custom message"
                        name="group1"
                        type="radio"
                        value={"custom"}

                    />
                    </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" hidden={this.state.RejectMessageShow}>
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={()=>{ this.saveHandler() }}>
                        Reject
                    </Button>
                </Modal.Footer>
              </Form>
            </Modal>

            <MUIDataTable
                style={{ minHeight: '500px'}}
                columns={columns}
                data={ this.state.items}
                title='Tasks for moderation'
                options={options}
            />
        </div>)
    }
}

export  {Moderation};