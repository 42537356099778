import React, { Component, useState } from "react";
import {bRequests, bSetStatus } from '../backend.js';
import MUIDataTable from "mui-datatables";
import  { Badge, Modal, Button, Form } from  'react-bootstrap'
import moment from "moment";


export default class BoardAdmin  extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false, items:[], Show:false};
        this.handleClose = () =>  this.setState({Show:false})
        this.handleShow = () =>  this.setState({Show:true})
    }
    componentDidMount() {
        bRequests(data=>{
            this.setState({
                   items:data.listing
                })
        })
    }

    render() {

        const columns = [
            { label: 'Company', name: 'company_name' },
            { label: 'Email', name: 'email'},
            { label: 'Assigned_to', name: 'assigned_to'},
            { label: 'Status', name: 'status', options:   {
                    customBodyRender:  (value)=>{

                        return <>
                            <Badge>
                                {value?value:"none"}
                            </Badge>
                        </>
                    }}},
            { label: 'Resume', name: 'resume_id' },
            { label: 'Actions', name: ' ', options:   {
                    customBodyRender:  (value)=>{

                        return <>
                            <Button variant="success" size={"sm"}>Resolve</Button>
                            <Button variant="danger" size={"sm"}>Reject</Button>
                        </>
                    }}},
        ]
        const options = {
            filterType: 'dropdown',
            responsive: 'stacked',
            selectableRows:'single'
        }
        return (<div style={{ maxWidth: '100%' }}>
            <MUIDataTable
                style={{ minHeight: '500px'}}
                columns={columns}
                data={ this.state.items}
                title='Board Requests'
                options={options}
            />
        </div>)
    }
}